<template>
  <div>
    <Breadcrumbs area="Configurações" :data="location" />
    <div class="content">
      <Panel :data="location" />
      <div class="primary" :class="profile && profile.company_data ? '' : 'pt-none'">
        <div v-if="!invitation" class="box table">
          <div class="tip">
            <h2>Dica</h2>
            <p>
              Aqui você gerencia as contas vinculadas e dá permissão para que elas possam ver dados da sua empresa no vDash.
            </p>
          </div>

          <div class="header">
            <h1 class="header__title">Contas vinculadas</h1>
            <button v-on:click="invitation = true">Vincular conta</button>
          </div>

          <div class="painel">
            <b-table :items="getTable.accounts" :fields="fields">
              <template #cell(acao)="row">
                <div class="btn-menu">
                  <img src="~@/assets/images/icones/btn-edite.svg" alt="Download" />
                  <div class="menu">
                    <div v-on:click="deleteAccount(row.item.id)">Remover conta</div>
                  </div>
                </div>
              </template>
            </b-table>
            <p class="painel__empty" v-if="accounts.length == 0">Sem dados</p>
          </div>

          <div class="header mt-xlargue" v-if="invitationPending.length > 0">
            <h1 class="header__title">Convites pendentes</h1>
          </div>

          <div class="painel" v-if="invitationPending.length > 0">
            <b-table :items="invitationPending" :fields="fields2">
              <template #cell(acao)="row">
                <div class="btn-menu">
                  <img src="~@/assets/images/icones/btn-edite.svg" alt="Download" />
                  <div class="menu">
                    <div @click="sendInvitation(row.item.email)">
                      Reenviar convite
                    </div>
                    <div v-on:click="deleteInvitation(row.item.id)">Remover</div>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </div>

        <!-- MODAL -->
        <div v-else class="box table edite">
          <div class="box-inputs">
            <div class="field">
              <label>E-mail</label>
              <input name="email" type="email" v-bind:value="email" v-on:input="email = $event.target.value" />
              <span>O usuário será notificado por e-mail.</span>
            </div>
          </div>
          <div class="box-inputs-checkbox">
            <div class="header">
              <label>Permissões</label>
              <div v-on:click="invitation = false" class="close">
                <img src="~@/assets/images/icones/close.svg" alt="close" />
              </div>
            </div>
            <div class="div-checkbox">
              <input type="checkbox" id="ler" name="ler" v-model="ler" disabled="disabled" />
              <label for="ler">Ler dados e gráficos</label>
            </div>
          </div>
          <div class="box-btn">
            <button v-on:click="sendInvitation(email); invitation = false">Enviar convite</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import Panel from "@/components/panel/Panel.vue";
import { getAccountsLinked, sendInvitation, getInvitationPending, deleteInvitation, deleteAccount } from "@/api/accounts";

export default {
  components: {
    Breadcrumbs,
    Panel,
  },
  data() {
    return {
      regras: [],
      breadcrumbs: [],
      panel: [],
      location: "",
      id: "",
      invitation: false,
      del: false,
      name: "",
      email: "",
      ler: true,
      editar: false,
      gerenciar: false,
      data: {
        title: {
          top: "",
          name: "Permissões da conta",
        },
      },
      fields: [
        { key: "nome", label: "Empresa" },
        { key: "email", label: "E-mail" },
        { key: "permissoes", label: "Permissões" },
        { key: "acao", label: "" },
      ],
      fields2: [
        { key: "email", label: "E-mail" },
        { key: "acao", label: "" },
      ],
    };
  },
  mounted() {
    this.fillData();
  },
  computed: {
    ...mapState({
      accounts: state => state.accounts.accountsLinked,
      invitationPending: state => state.accounts.invitationPending,
      profile: state => state.user.dadosCompletos,
    }),
    getTable() {
      let res = this.accounts;
      const accounts = [];
      res.map(item => {
        accounts.push({
          id: item.id,
          nome: item.company_owner.name,
          email: item.company_owner.email,
          permissoes:"Ler dados e gráficos",
          acao: "",
        });
      });

      return {
        title: {
          top: "",
          name: "Detalhamento",
        },
        accounts,
      };
    },
    userId() {
      let res = this.$store.state.user.dadosCompletos;
      return res.user_id;
    },
  },
  methods: {
    getAccountsLinked,
    sendInvitation,
    getInvitationPending,
    deleteInvitation,
    deleteAccount,
    fillData() {
      this.getAccountsLinked();
      this.getInvitationPending();
      this.location = "Contas vinculadas";
    },
    handleEditeClose() {
      this.edite = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  cursor: pointer;
  &:hover {
    .menu {
      display: block;
    }
  }
  .menu {
    position: absolute;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    top: 25px;
    z-index: 10;
    width: 145px;
    display: none;
    div {
      padding: 15px;
      transition: all 350ms ease-in-out;
      &:hover,
      &:active {
        background-color: #c2c2c2;
      }
    }
  }
}
.header {
  margin-bottom: 30px;
  &__title {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}
.primary {
  width: 100%;
  padding-top: 80px;
}
.setup {
  height: 100%;
  .page-container {
    margin: 30px 0;
  }
}
.content {
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
}

.box {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-top: 0 !important;
  max-width: calc(50% - 7.5px);
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 18px;
  box-shadow: 0 3px 6px #c3d0e029;
  border-radius: 20px;
  &:nth-child(2) {
    margin-right: 0;
  }
  .painel {
    position: relative;
    height: 300px;
    display: block;
    overflow: hidden;
    &__empty {
      text-align: center;
      width: 100%;
      padding: 40px 0;
      font-size: 14px;
      color: #c1c1c1;
    }
  }
  &.table {
    margin: 0;
    margin-top: 15px;
    height: auto;
    max-width: 100%;
    width: 100%;
    &:after {
      content: "";
      display: block;
    }
    .tip {
      font-weight: 500;
      font-size: 12px;
      text-align: left;
      width: 100%;
      max-width: 540px;
      height: 107px;
      background: #e9ecf0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 25px;
      margin-bottom: 35px;
      h2 {
        font-size: 16px;
        color: $dark;
        margin-bottom: 10px;
      }
      p {
        color: #717171;
        line-height: 1.3;
      }
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        background-color: #41a7ff;
        max-width: 330px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
    .painel {
      height: auto;
      overflow: inherit;
    }
  }
  &.edite {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .header {
      margin-bottom: 0;
    }
    .box-inputs {
      max-width: 50%;
      width: 100%;
      padding-right: 15px;
      &-checkbox {
        max-width: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 15px;
        padding-top: 0;
        padding-bottom: 0;
        .header {
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 5px;
          margin-bottom: 28px;
          label {
            font-size: 16px;
            font-weight: 600;
            color: #2c2c2c;
          }
        }
      }
      .field {
        display: flex;
        justify-content: center;
        flex-direction: column;
        &:first-child {
          margin-bottom: 40px;
        }
        label {
          font-size: 16px;
          font-weight: 600;
          color: #2c2c2c;
          margin-bottom: 5px;
        }
        input {
          height: 50px;
          border: 1px solid #e6e6e6;
          & + span {
            margin-top: 10px;
          }
        }
        span {
          font-size: 14px;
          font-weight: 200;
        }
      }
    }
    .box-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      button {
        background-color: #73cb53;
        max-width: 330px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .box-filter {
    justify-content: flex-end;
  }
  .box {
    max-width: 100%;
    margin-top: 30px;
    margin-right: 0;
    padding: 25px;
    &.table {
      margin-top: 30px;

      overflow: scroll;
      .box-inputs,
      .box-inputs-checkbox {
        max-width: 100%;
        padding: 0;
        order: 2;
      }
      .box-inputs-checkbox {
        margin-top: 15px;
        order: 1;
      }
      .box-btn {
        order: 3;
      }
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
